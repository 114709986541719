import React, {FC} from 'react';
import logo from '../img/logo-strap-white.svg';

type Props = {}

const Footer: FC<Props> = () => (
	<footer className="footer has-background-black has-text-white-ter">
		<div className="content has-text-centered">
			<img className="logo" src={logo} alt="Vega Financial Technology" />
      <div className="copyright">Copyright © 2019 Vega Financial Technology, 1 Fore Street Avenue, London, EC2Y 9DT.</div>
    </div>
	</footer>
);

export default Footer;
