import React, {FC, ReactNode} from 'react';

type HtmlContentProps = {
	content: string
	className?: string
}

type ContentProps = {
	content: ReactNode;
	className?: string;
}

export const HTMLContent: FC<HtmlContentProps> = ({content, className}) => (
	<div className={className} dangerouslySetInnerHTML={{__html: content}}/>
);

const Content: FC<ContentProps> = ({content, className}) => (
	<div className={className}>{content}</div>
);

export default Content;
