import React, {FC, useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import Navbar from './Navbar';
import useSiteMetadata from './SiteMetadata';
import './all.sass';

if (typeof window !== "undefined") {
	// eslint-disable-next-line global-require
	require("smooth-scroll")('a[href*="#"]', {
		speed: 500
	})
}

type Props = {
	children: React.ReactNode;
}

const TemplateWrapper: FC<Props> = ({children}) => {
	const {title, description} = useSiteMetadata();
	const [isTop, setIsTop] = useState(true);

	useEffect(() => {
		document.addEventListener('scroll', () => {
			const currentlyTop = window.scrollY < 400;
			if (currentlyTop !== isTop) {
				setIsTop(currentlyTop);
			}
		})
	});

	return (
		<>
			<Helmet>
				<html lang="en"/>
				<title>{title}</title>
				<meta name="description" content={description}/>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/img/apple-touch-icon.png?v=2"
				/>
				<link
					rel="icon"
					type="image/png"
					href="/img/favicon-32x32.png?v=2"
					sizes="32x32"
				/>
				<link
					rel="icon"
					type="image/png"
					href="/img/favicon-16x16.png?v=2"
					sizes="16x16"
				/>
				<link
					rel="mask-icon"
					href="/img/safari-pinned-tab.svg?v=2"
					color="#2be1ea"
				/>
				<meta name="msapplication-TileColor" content="#2be1ea" />
				<meta name="theme-color" content="#fff" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={title} />
				<meta property="og:url" content="/" />
				<meta property="og:image" content="/img/og-image.jpg" />
				<body className="has-navbar-fixed-top" />
			</Helmet>
			<Navbar isTop={isTop} />
			<div>{children}</div>
			<Footer/>
		</>
	)
};

export default TemplateWrapper;
