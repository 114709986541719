import classNames from 'classnames';
import React, {FC, useState} from 'react';
import {Link} from 'gatsby';
import logo from '../img/logo-min.svg';
import logoWhite from '../img/logo-min-white.svg';

type Props = {
	isTop: boolean;
}

const Navbar: FC<Props> = ({isTop}) => {
	const [active, setActive] = useState(false);
	const toggleHamburger = () => setActive(!active);
	const navClassName = classNames('navbar is-fixed-top', {
		'is-dark': !isTop
	});
	const hamburgerMenuClassName = classNames('navbar-burger burger', {
		'is-active': active
	});
	const navMenuClassName = classNames('navbar-menu', {
		'is-active': active
	});
	return (
		<nav
			className={navClassName}
			role="navigation"
			aria-label="main-navigation"
		>
			<div className="container">
				<div className="navbar-brand">
					<Link to="/" className="navbar-item" title="Logo" activeClassName="is-active">
						<img src={isTop ? logo : logoWhite} alt="Vega FT" style={{width: '88px'}}/>
					</Link>
					{/* Hamburger menu */}
					<div
						className={hamburgerMenuClassName}
						data-target="navMenu"
						onClick={toggleHamburger}
					>
						<span/>
						<span/>
						<span/>
					</div>
				</div>
				<div
					id="navMenu"
					className={navMenuClassName}
				>
					<div className="navbar-start has-text-centered">
						<Link className="navbar-item" to="/#introduction" activeClassName="is-active">
              Introduction
						</Link>
						<Link className="navbar-item" to="/#why" activeClassName="is-active">
							Why Vega?
						</Link>
						<Link className="navbar-item" to="/#how" activeClassName="is-active">
							How We Help
						</Link>
            <Link className="navbar-item" to="/#about" activeClassName="is-active">
              About Us
            </Link>
            <Link className="navbar-item" to="/#projects" activeClassName="is-active">
              Projects
            </Link>
						<Link className="navbar-item" to="/#testimonials" activeClassName="is-active">
							Testimonials
						</Link>
						<Link className="navbar-item" to="/#contact" activeClassName="is-active">
							Contact
						</Link>
            <Link className="navbar-item"  to="/blog"  activeClassName="is-active">
              Blog
            </Link>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
